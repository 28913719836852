export default {
  /**
   * Establecer cookie
   * @param {String} name Nombre del cookie
   * @param {String} value Valor del cookie
   * @param {String} days Días de duración del cookie antes de expirar
   */
  setCookie: (name, value, days) => {
    var expires = ""
    if (days) {
      var date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = "; expires=" + date.toUTCString()
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/"
  },
  /**
   * Obtener cookie
   * @param {String} name Nombre del cookie
   * @returns {String}
   */
  getCookie: name => {
    var nameEQ = name + "="
    var ca = document.cookie.split(";")
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === " ") c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  },
  /**
   * Eliminar cookie
   * @param {String} name Nombre del cookie
   */
  eraseCookie: name => {
    document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"
  },
}
