import React from "react"
import { Link } from "gatsby"

const IntroPageCustomer = ({ data, location }) => {
  return (
    <section className="intro__casosexito">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Link
          to={`/casos-de-exito/${location.search}`}
          className="link-to-return"
          aria-label="enlace"
        >
          <span className="flecha-izquierda">
            <svg
              width="16"
              height="10"
              viewBox="0 0 16 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 5L1 5M1 5L5.09091 9M1 5L5.09091 0.999999"
                stroke="#2C5C82"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          Regresar
        </Link>
        <p style={{ display: "flex", color: "#B5B5B5", gap: "12px" }}>
          Casos de Exito{" "}
          <hr style={{
            height: "100%", background: "#F5D312", width: "3px", border: "0",
          }} />
        </p>
      </div>
      <div>
        <img
          loading="lazy"
          src={data.image}
          alt="cliente"
          className={data.classlogo}
        />
      </div>
      <p className="text1-casos">{data.text1}</p>

      <div className="metric-casosexito">
        <img loading="lazy" src={data.metric1} alt="cliente" />
        {data.metric2 ? (<img loading="lazy" src={data.metric2} alt="cliente" />) : ""}
        {data.metric3 ? (
          <img loading="lazy" src={data.metric3} alt="cliente" />
        ) : (
          ""
        )}
      </div>

      <div className="flex__clientes">
        <p className="">{data.text2}</p>
        <div>{data.text3}</div>
      </div>

      {data.link === "#" ? <img loading="lazy" src={data.fotoone} alt="cliente" width={"100%"} /> :
        <Link
          href={data.link}
          target={data.link === "#" ? "_self" : "_blank"}
          rel="noreferrer"
        >
          <img loading="lazy" src={data.fotoone} alt="cliente" width={"100%"} />
        </Link>
      }
      <p className="text4-casos">{data.text4}</p>
    </section>
  )
}

export default IntroPageCustomer
