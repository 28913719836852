import React from "react"
const IntroPageCustomer = ({ title, text }) => {
  return (
    <section>
      <div style={{padding: "6px 0 0px"}}>        
        <p className="title6-casos"> {title}</p>
        <p>{text}</p>        
      </div>
    </section>
  )
}

export default IntroPageCustomer
