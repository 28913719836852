import React from "react"
import FotoKontigo from "@components/pageCasosdeExito/image/imgPhotoEmpresa/civa/FotoCiva.png"
import FotoKontigo2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/civa/FotoCiva2.png"

//logo de empresas clientes
import Civa from "@components/pageCasosdeExito/image/logoscustomer/Civa.svg"

//iconos de modulos
import whatsapp from "@components/pageCasosdeExito/image/iconcards/whatsapp.svg"
import campañas from "@components/pageCasosdeExito/image/iconcards/campañas.svg"
import chatbots from "@components/pageCasosdeExito/image/iconcards/chatbot.svg"
import blending from "@components/pageCasosdeExito/image/iconcards/blending.svg"

//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/civa/img1.svg"
import metrica2 from "@components/pageCasosdeExito/image/imgMetric/civa/img2.svg"
import metrica3 from "@components/pageCasosdeExito/image/imgMetric/civa/img3.svg"


const text1 = (
  <span className="">
    CIVA, con más de 50 años de experiencia en el sector de transporte, es una de las principales empresas en Perú, destacándose por su cobertura a nivel nacional y su amplia gama de servicios. Con flotas diferenciadas como Econociva, Superciva y Excluciva, la empresa ofrece a sus pasajeros una experiencia 360 a nivel nacional.
    <br /><br />
    La empresa ha logrado centralizar la gestión de sus comunicaciones, desde el correo electrónico hasta WhatsApp, permitiendo una atención más ágil y eficaz. Beex no solo ha mejorado la eficiencia del equipo, sino que ha permitido que cada solicitud, sin importar el canal, sea atendida de manera oportuna.
  </span>
)

const text2 = (
  <span>
    Con más de 1,700 colaboradores, CIVA es reconocida por su innovación constante y por ofrecer a sus pasajeros un servicio que combina comodidad, tecnología y seguridad. Con una flota moderna equipada con asientos reclinables de 180º y servicios exclusivos. Sin duda, CIVA se ha posicionado como una de las opciones preferidas para el transporte a nivel nacional.
    <br /><br />
    Además de ofrecer una amplia gama de servicios, como el transporte de pasajeros y carga, la empresa se distingue por su compromiso con la excelencia operativa, lo que le ha permitido ganarse la confianza de millones de clientes a lo largo de los años.
    <br /><br />
    Angel Rodas, Coordinador de Venta Digital en CIVA, ha sido el contacto directo entre la empresa y Beex para la integración de todos sus canales de contacto, el acceso a la API de WhatsApp Business y las automatizaciones en cada punto de contacto.
    <br /><br />
    Con Beex, CIVA ha mejorado su tiempo de respuesta en un 20% y ha visto un aumento en las ventas de hasta un +8%, aprovechando la API de WhatsApp Business, las automatizaciones con chatbots, y la integración de su canal de voz para una atención más personalizada.
  </span>
)

const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
    “Beex Contact Center nos ha permitido gestionar nuestras interacciones de forma más eficiente. Gracias a la centralización de todos nuestros canales, hemos mejorado significativamente la experiencia del cliente y optimizado nuestros procesos. Con esto, hemos aumentado nuestras ventas digitales en +8% respecto al periodo pasado.”
  </p>
)

const text4 = ""


const text5 = (
  <span>
    El uso de la API de WhatsApp Business ha sido crucial para mejorar la relación con los clientes de CIVA. Este canal ha permitido una comunicación más directa y en tiempo real, especialmente para confirmar itinerarios, gestionar cambios de boletos y resolver consultas rápidamente.
    <br /><br />
    Con el uso de chatbots, CIVA ha automatizado las preguntas frecuentes, aligerando la carga operativa y permitiendo al equipo concentrarse en casos más complejos. Sin duda, el automatizar este proceso también les permite atender consultas de forma más rápida y óptima, con una precisión para destacar en las interacciones iniciales.
    <br /><br />
    Además, el uso de campañas masivas de WhatsApp ha permitido a CIVA mantener una comunicación rápida y personalizada con sus pasajeros, fortaleciendo la fidelización y mejorando la satisfacción del cliente.
  </span>
)

const text6 = (
  <span>
    “Con la ayuda de Beex hemos optimizado nuestra comunicación con los pasajeros. La API de WhatsApp nos permite resolver dudas y gestionar cambios de manera inmediata, lo que ha elevado la calidad de nuestro servicio. Además, como consecuencia, hemos mejorado la experiencia de nuestros clientes, logrando reducir nuestro tiempo medio operativo hasta 3.7min.”
  </span>
)

const text7 = (
  <span>
    La integración del correo electrónico en la operación de CIVA ha sido esencial para centralizar toda la comunicación en una sola plataforma. Anteriormente, las solicitudes enviadas por este canal podían perderse o demorarse, afectando la experiencia del cliente.
    <br /><br />
    Ahora, con el correo electrónico como canal oficial, CIVA puede gestionar de manera eficiente las solicitudes de clientes que prefieren este medio, asegurando que cada consulta reciba una respuesta oportuna. Esta centralización ha mejorado la coordinación interna y ha permitido que todas las interacciones con los clientes estén bien documentadas y organizadas en un solo lugar.
  </span>
)

const text8 = (
  <span>
    El canal de voz en CIVA ha sido optimizado mediante la implementación de sistemas IVR. Con esta tecnología se ha podido segmentar automáticamente las consultas, lo que ha mejorado significativamente la eficiencia del equipo de atención al cliente.
    <br /><br />
    El IVR de CIVA permite que los pasajeros sean dirigidos al área correspondiente según sus necesidades, reduciendo los tiempos de espera y mejorando la precisión en la atención.
    <br /><br />
    Por otro lado, la empresa está desarrollando un proyecto grande para medir la satisfacción de los pasajeros, lo que les permitirá obtener valiosos insights para seguir mejorando su servicio. Para ello, le darán uso a nuestro módulo de IVR encuestas de satisfacción.
  </span>
)
const text9 = (
  <span>
    “El uso del canal de voz y los IVR nos ha ayudado a mejorar la experiencia del cliente, permitiendo una atención directa y rápida, optimizando el flujo de atención y segmentando consultas de manera eficiente. El apoyo de Beex ha sido fundamental en este proceso.”
  </span>
)

const text10 = (
  <span>
    El soporte y acompañamiento de Beex han sido fundamentales para el éxito de la operación en CIVA. Desde el primer día, el equipo de Beex ha brindado un soporte ágil, resolviendo cualquier inconveniente en el menor tiempo posible.
    <br /><br />
    La capacidad de respuesta inmediata ha sido clave para mantener una operación fluida, especialmente en momentos críticos. CIVA valora la dedicación y personalización del equipo de Beex, que no solo se limita a solucionar problemas, sino que también ofrece mejoras constantes para optimizar la atención al cliente.
  </span>
)
const text11 = (
  <span>
    “El soporte de Beex ha sido excelente, brindando acompañamiento constante y soluciones personalizadas a nuestras necesidades. La agilidad para resolver problemas y la atención proactiva han sido claves para mantener nuestra operación fluida. Si duda, nos sentimos muy satisfechos con Beex. Han permitido que nuestra operación sea más eficiente, flexible y que podamos ofrecer una atención superior a nuestros clientes.”
  </span>
)

const text12 = (
  <span>
    Estos son algunos procesos con los que Beex ayuda a CIVA en su operación de atención al cliente:
  </span>
)



const data = {
  intro: {
    image: Civa,
    link: "#",
    classlogo: "logo__clientes--kontigo",
    text1: text1,
    text2: text2,
    text3: text3,
    fotoone: FotoKontigo,
    text4: text4,
    title5: "WhatsApp para el sector transporte",
    text5: text5,
    text6: text6,
    title7: "Mejora en campañas de mailing",
    text7: text7,
    fototwo: FotoKontigo2,
    title8: 'Optimización del canal de voz con IVR',
    text8: text8,
    text9: text9,
    title10: "Soporte y acompañamiento post venta",
    text10: text10,
    text11: text11,
    text12: text12,

    metric1: metrica1,
    metric2: metrica2,
    metric3: metrica3,
  },

  cards: [
    {
      title: "WhatsApp",
      img: whatsapp,
      info:
        "WhatsApp se ha consolidado como una herramienta clave para CIVA. La implementación de la API de WhatsApp Business ha optimizado la comunicación con los pasajeros, permitiendo gestionar consultas de manera rápida y eficiente, mejorando significativamente la experiencia del cliente."
    },
    {
      title: "Chatbots",
      img: chatbots,
      info: "La integración de chatbots en WhatsApp ha permitido a CIVA atender consultas frecuentes de manera automatizada. Esto ha liberado al equipo de atención al cliente para concentrarse en solicitudes más complejas, mejorando los tiempos de respuesta y garantizando una atención más rápida y precisa desde el primer contacto.",
    },
    {
      title: "Campañas masivas",
      img: campañas,
      info:
        "Las campañas masivas de WhatsApp han transformado la comunicación de CIVA. Gracias a la segmentación detallada y el uso de plantillas automatizadas, CIVA puede enviar mensajes personalizados a una gran cantidad de usuarios, incrementando su tasa de respuesta y efectividad.",
    },
    {
      title: "IVR",
      img: blending,
      info:
        "El IVR de Beex ha permitido a CIVA automatizar y organizar las llamadas entrantes de manera eficiente. Con esta tecnología, los pasajeros pueden ser redirigidos al área adecuada de forma rápida, optimizando los tiempos de atención y mejorando la satisfacción general del servicio.",
    },
  ],
}

export default data
