import React, { useEffect, useState } from "react"
import img from "./images/Galleta.svg"
import cookieMixin from "@mixins/cookie"
import { Link } from "gatsby"


const CtaCookies = () => {
  const [isOpenCookies, setIsOpenCookies] = useState(false)
  useEffect(() => {
    // Si tiene permiso de los cookies
    const viewed_cookie_policy = cookieMixin.getCookie("viewed_cookie_policy")
    if (viewed_cookie_policy !== "yes") {
      setIsOpenCookies(true)
    }
  }, [])

  const closeModalCookies = () => {
    setIsOpenCookies(false)
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    cookieMixin.setCookie("viewed_cookie_policy", "yes", 730)
  }

  const CookieModal = () => (
    <section className="container-cta-cookies">
      <div className="cta-cookies">
        <img src={img} alt="galleta" />
        <div>
          <div>
            <p className="strong-cookies">Cookies</p>
            <p className="title-cookies">Cookies</p>
          </div>
          <p className="descripcion-cookies">
            {" "}
            Utilizamos cookies para mejorar tu experiencia en nuestra web.
            Algunas cookies son esenciales para el servicio y sin ellas el sitio
            no funcionará como esperas. Para más información, puedes ingresar a
            nuestra
            <Link href="/politicas-cookies" target="_blank"   rel="noopener noreferrer">
              {" "}
              Política de Cookies
            </Link>{" "}
            y a nuestra
            <Link href="/politicas-privacidad" target="_blank"   rel="noopener noreferrer">
              {" "}
              Política de Privacidad
            </Link>{" "}
            .
          </p>
        </div>
      </div>
      <div className="btns-cookies">
        <Link
          href="https://tools.google.com/dlpage/gaoptout/"
          className="btn-cookies-config"
        >
          Configurar
        </Link>
        <button
          className="btn-cookies-ok"
          onClick={() => {
            closeModalCookies(false)
          }}
        >
          Aceptar
        </button>
      </div>
    </section>
  )

  return <>{isOpenCookies ? <CookieModal close={closeModalCookies} /> : ""}</>
}

export default CtaCookies
