import React from "react"

const Cards = ({ data}) => (
  <section    className="cards__casosexito"  >
    <section className="container-cards-whatsapp ">
      <div className="row-whatsapp-functions ">
        {data.map(elemento => {
          return (
            <div
              key={elemento.title}
              className="card-cases-use card-shadow"
            >
              <div className="cards-whatsapp-functions text-left">
                <div className="pronto-cards">
                  <p className={elemento.class}>{elemento.status}</p>
                </div>
                <div className="card-img1 card-img1-left">
                  <img
                    loading="lazy"
                    src={elemento.img}
                    className="card-icon"
                    alt="..."
                  />
                </div>
                <p className="card-title-inbox" style={{ fontWeight: "bold" }}>
                  {elemento.title}{" "}
                </p>
                <p className="card-body1">{elemento.info}</p>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  </section>
)
export default Cards
